import React from 'react';
import logo from '../../assets/nav_logo.png';
import './footer.css';

const Footer = ({ contact, links, footer }) => (
  <div className="gpt3__footer section__padding">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">{footer?.text}</h1>
    </div>

    <div className="gpt3__footer-links">
      <div className="gpt3__footer-links_logo">
        <img src={logo} alt="gpt3_logo" />
        <p>Croatia, Europe</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Social Media</h4>
        {links?.map((link) => {
          return (
            <p>
              <a href={link?.text}>{link?.title}</a>
            </p>
          );
        })}
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Company</h4>
        <a href="#contact">
          <p>Contact</p>
        </a>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Get in touch</h4>
        <a href={`mailto:${contact?.email}`}>
          <p>{contact?.email}</p>
        </a>
      </div>
    </div>

    <div className="gpt3__footer-copyright">
      <p>{footer?.title}</p>
    </div>
  </div>
);

export default Footer;
