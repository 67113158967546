import React from 'react';
import Feature from '../../components/feature/Feature';
import './AboutUs.css';

const AboutUs = ({ aboutUs, aboutUsSub }) => {
  return (
    <div className="gpt3__whatgpt3 section__margin" id="aboutUs">
      <div className="gpt3__whatgpt3-feature">
        <Feature title="About Us" text={aboutUs?.text} />
      </div>
      <div className="gpt3__whatgpt3-heading">
        <h1 className="gradient__text">{aboutUs?.title}</h1>
        {/*<p>Explore the Library</p>*/}
      </div>
      <div className="gpt3__whatgpt3-container">
        {aboutUsSub?.map((content) => {
          return <Feature title={content.title} text={content.text} />;
        })}
      </div>
    </div>
  );
};

export default AboutUs;
