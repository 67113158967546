import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { paths } from '../../constants';

export const fetchData = createAsyncThunk('strapi/data', async (thunkAPI) => {
  try {
    const response = await fetch(`${paths.api.HOME}`, {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });
    let data = await response.json();
    if (response.status === 200) {
      return data;
    } else {
      return thunkAPI.rejectWithValue(data);
    }
  } catch (e) {
    console.log('Error', e.response.data);
    thunkAPI.rejectWithValue(e.response.data);
  }
});
export const strapiSlicer = createSlice({
  name: 'strapiData',
  initialState: {
    fetchedData: null,
    imagePath: null,
    aboutUs: null,
    aboutUsSub: null,
    features: null,
    possibilities: null,
    featuresContainer: null,
    contact: null,
    links: null,
    footer: null,
    isFetching: false,
    isSuccess: false,
    isError: false,
    errorMessage: '',
  },
  reducers: {
    // Reducer comes here
  },
  extraReducers: {
    [fetchData.fulfilled]: (state, { payload }) => {
      state.isFetching = false;
      state.isSuccess = true;
      state.fetchedData = payload?.data?.attributes;
      state.imagePath = payload?.data?.attributes?.image?.data?.attributes?.url;
      state.aboutUs = payload?.data?.attributes?.aboutUs;
      state.aboutUsSub = payload?.data?.attributes?.aboutUsSub;
      state.features = payload?.data?.attributes?.features;
      state.possibilities = payload?.data?.attributes?.possibilities;
      state.featuresContainer = payload?.data?.attributes?.featuresContainer;
      state.contact = payload?.data?.attributes?.contact;
      state.links = payload?.data?.attributes?.Links;
      state.footer = payload?.data?.attributes?.footer;
    },
    [fetchData.pending]: (state) => {
      state.isFetching = true;
    },
    [fetchData.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.errorMessage = payload.message;
    },
  },
});
export const strapiSelector = (state) => state.strapi;
