import React from 'react';
import possibilityImage from '../../assets/possibility-api.png';
import './possibility.css';
import Feature from '../../components/feature/Feature';

const Possibility = ({ possibilities, featuresContainer }) => (
  <div className="gpt3__possibility section__padding" id="possibility">
    <div className="gpt3__possibility-image">
      <img src={possibilityImage} alt="possibility" />
    </div>

    <div className="gpt3__possibility-content">
      <h4>What We Do?</h4>
      <h1 className="gradient__text">{possibilities?.title}</h1>
      <div className="gpt3__features-container">
        {featuresContainer?.map((item, index) => (
          <Feature
            title={item?.title}
            text={item?.text}
            link={item?.link}
            key={item?.title + index}
          />
        ))}
      </div>
    </div>
  </div>
);

export default Possibility;
