import React from 'react';
import './header.css';
import logo from '../../assets/logo-header.png';

const Header = ({ fetchedData, imagePath }) => {
  return (
    <div className="gpt3__header section__padding" id="home">
      <div className="gpt3__header-content">
        <h1 className="gradient__text">{fetchedData?.title}</h1>
        <p>{fetchedData?.text}</p>

        {/* <div className="gpt3__header-content__input">
          <input type="email" placeholder="Your Email Address" />
          <button type="button">Get Started</button>
        </div>*/}
      </div>

      <div className="gpt3__header-image">
        <img src={logo} alt="ai" />
      </div>
    </div>
  );
};

export default Header;
