import React, { useState } from 'react';
import { RiCloseLine, RiMenu3Line } from 'react-icons/ri';
import logo from '../../assets/nav_logo.png';
import './navbar.css';
import pathnames from '../../constants/pathnames';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <div className="gpt3__navbar">
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo">
          <img src={logo} alt="logo" />
        </div>
        <div className="gpt3__navbar-links_container">
          {pathnames.map((pathname) => {
            return (
              <p>
                <a href={pathname.href}>{pathname.label}</a>
              </p>
            );
          })}
        </div>
      </div>
      {/*<div className="gpt3__navbar-sign">
        <p>Sign in</p>
        <button type="button">Sign up</button>
      </div>*/}
      <div className="gpt3__navbar-menu">
        {toggleMenu ? (
          <RiCloseLine
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(false)}
          />
        ) : (
          <RiMenu3Line
            color="#fff"
            size={27}
            onClick={() => setToggleMenu(true)}
          />
        )}
        {toggleMenu && (
          <div className="gpt3__navbar-menu_container scale-up-center">
            <div className="gpt3__navbar-menu_container-links">
              {pathnames.map((pathname) => {
                return (
                  <p>
                    <a href={pathname.href}>{pathname.label}</a>
                  </p>
                );
              })}
            </div>
            {/*<div className="gpt3__navbar-menu_container-links-sign">
              <p>Sign in</p>
              <button type="button">Sign up</button>
            </div>*/}
          </div>
        )}
      </div>
    </div>
  );
};
export default Navbar;
