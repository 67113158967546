import { paths } from './index';

const pathnames = [
  {
    label: 'About Us',
    path: paths.ABOUT_US,
    href: '#aboutUs',
  },
  {
    label: 'Contact',
    path: paths.CONTACT,
    href: '#contact',
  },
  {
    label: 'Blog',
    path: paths.ARTICLES,
    href: '#blog',
  },
  {
    label: 'What We Do',
    path: paths.ARTICLES,
    href: '#possibility',
  },
];

export default pathnames;
