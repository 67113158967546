import React, { useEffect } from 'react';

import { AboutUs, Blog, Footer, Header, Possibility } from '../../containers';
import { Contact, Navbar } from '../../components';

import './index.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData, strapiSelector } from '../../features/strapi/strapiSlicer';

const HomePage = () => {
  const dispatch = useDispatch();
  const data = useSelector(strapiSelector);
  const {
    fetchedData,
    imagePath,
    aboutUs,
    aboutUsSub,
    featuresContainer,
    possibilities,
    contact,
    links,
    footer,
  } = data;
  useEffect(() => {
    dispatch(fetchData());
  }, [dispatch]);
  return (
    <div className="App">
      <div className="gradient__bg">
        <Navbar />
        <Header fetchedData={fetchedData} imagePath={imagePath} />
      </div>
      {/*<Brand />*/}
      <AboutUs aboutUs={aboutUs} aboutUsSub={aboutUsSub} />
      {/*<Features features={features} featuresContainer={featuresContainer} />*/}
      <Possibility
        possibilities={possibilities}
        featuresContainer={featuresContainer}
      />
      <Contact contact={contact} />
      <Blog />
      <Footer contact={contact} links={links} footer={footer} />
    </div>
  );
};

export default HomePage;
