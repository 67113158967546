import React from 'react';
import './contact.css';

const Contact = ({ contact }) => (
  <div className="gpt3__cta" id="contact">
    <div className="gpt3__cta-content">
      <h3>
        {contact?.title}
        <br />
        <a href={`mailto:${contact?.email}`}>{contact?.email}</a>
      </h3>
    </div>
    <div className="gpt3__cta-btn">
      <button type="button">
        <a href={`mailto:${contact?.email}`}>Contact Us</a>
      </button>
    </div>
  </div>
);

export default Contact;
