// eslint-disable-next-line import/no-anonymous-default-export
export default {
  HOME: '/',
  ABOUT_US: '/about-us',
  CONTACT: '/contact',
  ARTICLES: '/articles',

  api: {
    HOME: `${process.env.REACT_APP_API_KEY}/api/home-page?populate=*`,
  },

  build: (path, ...params) => {
    params.reverse();
    return path.replace(/(:\w+)/g, () => params.pop());
  },
};
